/**
 * Checks if the string is valid sitemap entry alias eg: uppercase and underscores
 * @param {string} str - A string that is checked against
 */
export const isStringAlias = (str = '') => {
	const r = new RegExp('^[A-Z0-9_]+$', 'g');
	return r.test(str);
};

/**
 * Determines if the string passed in is an absolute url
 * @param {string} str - A string that is checked against
 */
export const isStringAbsoluteUrl = (str = '') => {
	const r = new RegExp('^(?:[a-z]+:)?//', 'i');
	return r.test(str);
};

/**
 * Determines the url type passed in either as ALIAS, ABSOLUTE or RELATIVE
 * @param {string} url - A string value with a URL string or page alias
 */
export const determineUrlType = (url = '') => {
	if (isStringAlias(url)) {
		return 'ALIAS';
	} else if (isStringAbsoluteUrl(url)) {
		return 'ABSOLUTE';
	} else {
		return 'RELATIVE';
	}
};

/**
 * Sets the URL string based on sitemap request
 * Returns a string value after requesting a path from the sitemap service
 * @param {object} sitemap - An object containing helper functions for sitemap entries
 * @param {string} url - A string value with a URL string or page alias
 */
export const setUrl = (sitemap, url = '') => {
	switch (determineUrlType(url)) {
		case 'ALIAS':
			return sitemap.has(url) ? sitemap.get(url) : '/404.htm';
		default:
			return url || '/404.htm';
	}
};
