export const DDC_ICON_PREFIX = 'ddc-icon-';

// WIAPI page modify locations
export const SERVICE_SCHEDULE_BUTTON = 'schedule-service';
export const LATENCY_TRACKING_SECRET = 'ws-content-cta-latency-tracking';

// Icon types
export const SYSTEM = 'system';
export const SPOT = 'spot';

// Flags
export const FLAG_CONTENT_CTA_ATF = 'ws-content-cta-render-atf'; // US1133164

// Default resize images for desktop and mobile
export const RESIZE_DEFAULT_DESKTOP = 640;
export const RESIZE_DEFAULT_MOBILE = 410;
export const BLANK_GIF = '/static/images/blank.gif';
