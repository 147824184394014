import { triggerTrackingEvent } from './analytics';

export const handleWidgetClick = ({
	event,
	url,
	enableOpenNewWindow,
	elementCTA,
	windowId,
	isClickableModal,
	wiapiOnClick,
	ariaLabel = '',
	isAnchor,
	reactElementText
}) => {
	event.preventDefault();
	triggerTrackingEvent({
		element: 'ws-content-cta Call to action',
		elementCTA,
		url,
		elementType: isAnchor ? 'anchor' : 'notAnchorNorButton',
		result: `Navigated to ${url}`,
		windowId,
		ariaLabel,
		reactElementText
	});

	if (wiapiOnClick) {
		/** WIAPI is hijacking the default click behaviour of the widget */
		wiapiOnClick?.();
	} else if (!isClickableModal) {
		if (enableOpenNewWindow) {
			window.open(url);
		} else {
			window.location.href = url;
		}
	}
};

export const handleButtonClick = ({
	event,
	isClickableDivElement,
	element,
	elementCTA,
	result,
	windowId,
	wiapiOnClick
}) => {
	// Disable event to allow clickable wrapper to apply window.location
	if (isClickableDivElement || wiapiOnClick) {
		event.preventDefault();
	}
	triggerTrackingEvent({
		element,
		elementCTA,
		url: result,
		elementType: 'button',
		result: `Navigated to ${result}`,
		windowId
	});
	if (wiapiOnClick) {
		wiapiOnClick();
	}
};
