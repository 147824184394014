/**
 * Filters undefined, null or empty strings from a list of comma separated items, joins them and returns a string
 * Returns null if 'classes' is empty
 * @param {object} classes - An arguments object
 */
export const setClassNames = (...classes) => {
	const classList = new Set(classes);
	return [...classList].filter((c) => c).join(' ') || null;
};

/**
 * Determines the dialog related class that needs to be set
 * @param {bool} isClickableModal - Should the element trigger a modal
 * @param {string} deviceType - The request data device ('MOBILE', 'DESKTOP')
 */
export const setDialogClasses = (isClickableModal, deviceType) => {
	let dialogClass = null;
	if (isClickableModal) {
		dialogClass = 'dialog';
	} else if (deviceType === 'MOBILE') {
		dialogClass = 'slidein-disabled';
	}

	return dialogClass;
};

/**
 * Logic to apply default classes depends on position and actions
 * @param {bool} enableClickable - Should the element is clickable
 * @param {bool} isHovered - Should the element be hovered
 * @param {string} imageLocation - Determines the position where the element is
 * @param  {...object} classes - An arguments object
 * @returns string containing all classes
 */
export const setWrapperClassNames = (
	enableClickable,
	isHovered,
	imageLocation,
	...classes
) => {
	const classNames = ['cta', 'd-flex', 'position-relative', ...classes];

	if (
		imageLocation === 'background-right' ||
		imageLocation === 'background-left'
	) {
		classNames.push('flex-row');

		if (imageLocation === 'background-right') {
			classNames.push('flex-row-reverse');
		}
	} else {
		classNames.push('flex-column');
	}

	if (enableClickable) {
		classNames.push('pointer', 'text-no-decoration');

		if (isHovered) {
			classNames.push('hover');
		}
	}

	return setClassNames.apply(this, classNames);
};
