import React from 'react';
import { useFlags } from 'wsm-common-data';
import { useDispatch, useSelector } from 'react-redux';
import { usePrefs, useLabels, useSitemap } from 'ws-scripts/modules/common';

import { setHover } from '../actions/hover';
import { getCtaType } from '../utilities/helpers/wiapi';
import { handleWidgetClick } from '../utilities/events';
import { reportToNewRelic } from '../utilities/monitoring';
import {
	LATENCY_TRACKING_SECRET,
	FLAG_CONTENT_CTA_ATF
} from '../global-constants';
import { getHtmlWithDLData } from '../utilities/helpers/text';
import { isStringAlias, setUrl } from '../utilities/helpers/links';
import { setWidgetConfiguration } from '../utilities/configuration';
import {
	setClassNames,
	setDialogClasses,
	setWrapperClassNames
} from '../utilities/classes';

import Background from '../components/Background';
import ContentWrapper from '../components/ContentWrapper';

import '../global-sass/main.scss';

const setStyles = (widgetHeight = null) => {
	const styles = {};

	if (widgetHeight) {
		styles.height = widgetHeight;
	}

	return styles;
};

const Widget = () => {
	const flags = useFlags();
	const flagRenderATF = flags[FLAG_CONTENT_CTA_ATF];

	const prefs = usePrefs();
	const labels = useLabels();
	const sitemap = useSitemap();
	const dispatch = useDispatch();
	const {
		hover: { isHovered },
		widgetInfo: { deviceType, windowId, isPriorityContent }
	} = useSelector((state) => state);

	const [timing, setTiming] = React.useState(false);
	const [wiapiOverrides, setWiapiOverrides] = React.useState();

	const latencyTracking = {
		eventName: 'wsContentCTALatencyTracking',
		eventStart: 0,
		totalRenderTime: 0
	};

	if (!timing) {
		latencyTracking.eventStart = Date.now();
	}

	const initWiapiHandler = () => {
		const currentCtaUrl = setUrl(sitemap, (prefs.url || '').trim());
		const currentCtaAlias = isStringAlias(prefs.url) ? prefs.url : '';
		const ctaType = getCtaType(currentCtaUrl, currentCtaAlias);

		const handleWiapiModificationUpdate = ({
			detail: { location } = {}
		}) => {
			if (ctaType === location) {
				setWiapiOverrides({
					location,
					...window.DDC.PrivateAPI.getPageLevelModifications(location)
				});
			}
		};

		window.addEventListener(
			'wiapiPageModificationUpdate',
			handleWiapiModificationUpdate
		);

		// On widget load check if there is any page level modification already
		// available for the widget type. If, so call the wiapi modification handler
		if (
			window.DDC.PrivateAPI &&
			typeof window.DDC.PrivateAPI.getPageLevelModifications ===
				'function' &&
			window.DDC.PrivateAPI.getPageLevelModifications(ctaType)
		) {
			handleWiapiModificationUpdate({
				detail: {
					location: ctaType
				}
			});
		}
	};

	// On widget mount.
	// Wire up the wiapi event listener
	React.useEffect(() => {
		initWiapiHandler();
		latencyTracking.totalRenderTime =
			Date.now() - latencyTracking.eventStart;
		setTiming(true);

		// Reporting to new relic here as this functions similarly to componentDidMount
		if (flags[LATENCY_TRACKING_SECRET]) {
			reportToNewRelic(latencyTracking.eventName, latencyTracking);
		}
	}, []);

	const { displayStyle } = prefs;

	const widgetConfiguration = setWidgetConfiguration(
		deviceType,
		isHovered,
		displayStyle,
		prefs,
		sitemap,
		windowId,
		wiapiOverrides,
		isPriorityContent,
		flagRenderATF
	);
	const {
		widgetHeight,
		dialogWindowWidth,
		enableDialogWindow,
		enableOpenNewWindow,
		enableClickable,
		url,
		wiapiOnClick,
		showButton,
		imageLocation,
		headingLabel,
		contentLabel,
		buttonLabel,
		accessibleDescription,
		dialogWindowTitleLabel,
		widgetBackgroundColor,
		widgetTransitionProperty,
		widgetTransitionTimingFunction,
		widgetTransitionDuration,
		backgroundWrapperWidth,
		backgroundWrapperClasses,
		wiapiDataAttr
	} = widgetConfiguration;

	const isClickable = enableClickable && url !== '';
	const isAnchorElement = isClickable && !showButton;
	const isClickableElement = isClickable && isAnchorElement;
	const isClickableModal =
		enableDialogWindow && (isClickableElement || isAnchorElement);
	const WidgetElement = isAnchorElement ? 'a' : 'div';
	const target = enableOpenNewWindow && !enableDialogWindow ? '_blank' : '';

	const headingText = headingLabel && labels.getHTML(headingLabel);
	const dialogWindowTitleText = labels.get(dialogWindowTitleLabel);
	const ariaLabel = labels.get(accessibleDescription);

	return (
		<WidgetElement
			className={setWrapperClassNames(
				enableClickable,
				isHovered,
				imageLocation,
				widgetBackgroundColor,
				widgetTransitionProperty,
				widgetTransitionTimingFunction,
				widgetTransitionDuration,
				setDialogClasses(isClickableModal, deviceType)
			)}
			style={setStyles(widgetHeight)}
			href={isAnchorElement ? url : null}
			aria-label={ariaLabel || null}
			aria-labelledby={
				!ariaLabel ? `${windowId}-heading ${windowId}-button` : null
			}
			role={isClickableElement ? 'button' : null}
			data-href={isClickableModal ? url : null}
			data-modal={isClickableModal ? 'true' : null}
			data-title={
				isClickableModal && dialogWindowTitleText
					? dialogWindowTitleText
					: null
			}
			data-width={
				isClickableModal && dialogWindowWidth ? dialogWindowWidth : null
			}
			target={isAnchorElement && target ? target : null}
			onClick={
				isClickable
					? (event) =>
							handleWidgetClick({
								event,
								url,
								enableOpenNewWindow,
								elementCTA: headingLabel,
								windowId,
								isClickableModal,
								wiapiOnClick,
								ariaLabel,
								isAnchor: isAnchorElement,
								reactElementText:
									typeof headingText?.props?.children ===
									'string'
										? headingText?.props?.children
										: ''
							})
					: null
			}
			onMouseEnter={() => enableClickable && dispatch(setHover(true))}
			onMouseLeave={() => enableClickable && dispatch(setHover(false))}
			{...(isAnchorElement && wiapiDataAttr)}
		>
			{imageLocation !== 'foreground' && (
				<div
					className={setClassNames(
						backgroundWrapperWidth,
						backgroundWrapperClasses
					)}
					style={{ overflow: 'hidden' }}
				>
					<Background {...widgetConfiguration} />
				</div>
			)}
			<ContentWrapper
				{...{
					widgetConfiguration,
					windowId,
					deviceType,
					isClickableElement,
					isHovered,
					imageLocation,
					headingText,
					contentText:
						contentLabel &&
						getHtmlWithDLData(labels.get(contentLabel)),
					buttonText: buttonLabel && labels.getHTML(buttonLabel),
					dialogWindowTitleText,
					target
				}}
			/>
		</WidgetElement>
	);
};

export default Widget;
