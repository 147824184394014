import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	isResponsiveImageType,
	defaultImgClassList,
	getImgLoadingProps
} from '../utilities/images';
import {
	setBackgroundStyles,
	setBackgroundClassNames
} from '../utilities/background';
import { optimizeImage } from '../utilities/helpers/images';
import {
	RESIZE_DEFAULT_DESKTOP,
	RESIZE_DEFAULT_MOBILE
} from '../global-constants';

const Background = (props) => {
	const {
		showImage,
		imageLocation,
		imageUrl,
		imagePosition,
		imageSize,
		backgroundColor,
		backgroundGradient,
		backgroundRepeat,
		backgroundTransitionProperty,
		backgroundTransitionTimingFunction,
		backgroundTransitionDuration,
		backgroundWidthMultiplier,
		foregroundImageCircle,
		foregroundImageHeight,
		foregroundImageWidth,
		foregroundImageMargin,
		foregroundImagePadding,
		foregroundImageAlignment,
		isPriorityContent,
		flagRenderATF,
		deviceType
	} = props;

	const lazyImageRef = useRef();
	const resizeDefault =
		deviceType === 'DESKTOP'
			? RESIZE_DEFAULT_DESKTOP
			: RESIZE_DEFAULT_MOBILE;
	const defaultWidth = parseInt(
		resizeDefault * parseFloat(backgroundWidthMultiplier),
		10
	);
	const optimized = optimizeImage(imageUrl, defaultWidth);
	const [resizeImageUrl, setResizeImageUrl] = useState(() => {
		// Resize image when below the fold
		const belowTheFold =
			imageLocation === 'foreground' ? imageUrl : optimized.src;
		// Resize image when above the fold
		const aboveTheFold =
			imageLocation === 'foreground' ? imageUrl : optimized.src;
		return !isPriorityContent ? belowTheFold : aboveTheFold;
	});
	const [lazyImageElement, setLazyImageElement] = useState([]);
	const hasResponsiveImage = isResponsiveImageType(
		resizeImageUrl,
		imageLocation
	);
	const hasBackground =
		showImage === false &&
		imageLocation !== 'foreground' &&
		(backgroundColor !== '' || backgroundGradient !== '');

	const replaceDynamicBackgroundImage = (el, newUrl) => {
		const { backgroundImage } = el.style;
		const imageURL = backgroundImage.replace(
			/url\(.+\)/,
			`url("${newUrl}")`
		);
		if (imageURL !== backgroundImage) {
			return imageURL;
		}
		return backgroundImage;
	};
	useEffect(() => {
		// Load image above the fold or resize param when imageLocation is foreground
		if (isPriorityContent || !hasResponsiveImage) {
			return;
		}

		window?.addEventListener('load', () => {
			setLazyImageElement(
				document.querySelectorAll('.lazy-widgets-content-cta-image')
			);
			const parentWidth =
				lazyImageRef?.current?.parentElement?.clientWidth;
			const parentDefaultWith = parseInt(
				parentWidth * parseFloat(backgroundWidthMultiplier),
				10
			);
			setResizeImageUrl(optimizeImage(imageUrl, parentDefaultWith).src);
		});

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const divEl = entry.target;
					// get image element inside div element
					const imageEl = divEl.children[0];
					if (imageEl && imageEl.getAttribute('data-src')) {
						imageEl.setAttribute(
							'src',
							`${imageEl.getAttribute('data-src')}`
						);
						divEl.style.backgroundImage =
							replaceDynamicBackgroundImage(
								divEl,
								`${imageEl.getAttribute('data-src')}`
							);
						divEl.setAttribute('data-lazy-initialized', true);
						imageEl.classList.remove(
							'lazy-widgets-content-cta-image'
						);
						imageEl.removeAttribute('data-src');
						observer.unobserve(divEl);
					}
				}
			});
		});

		if (lazyImageElement.length > 0) {
			lazyImageElement.forEach((el) => {
				observer.observe(el.closest('div'));
			});
		}
	}, [
		backgroundWidthMultiplier,
		hasResponsiveImage,
		imageLocation,
		imageUrl,
		isPriorityContent,
		lazyImageElement,
		lazyImageElement.length,
		optimized.src
	]);

	const renderResizeImage = () => {
		return resizeImageUrl;
	};

	if (showImage || hasBackground) {
		return (
			<div
				ref={lazyImageRef}
				className={setBackgroundClassNames(
					imageLocation,
					foregroundImageCircle,
					foregroundImageMargin,
					foregroundImagePadding,
					foregroundImageAlignment,
					backgroundColor,
					isPriorityContent,
					flagRenderATF,
					// ...classes
					backgroundTransitionProperty,
					backgroundTransitionTimingFunction,
					backgroundTransitionDuration
				)}
				style={setBackgroundStyles(
					showImage,
					imageLocation,
					renderResizeImage(),
					imagePosition,
					imageSize,
					backgroundGradient,
					backgroundRepeat,
					foregroundImageHeight,
					foregroundImageWidth,
					hasResponsiveImage
				)}
				data-testid="background"
			>
				{hasResponsiveImage && (
					<img
						// default props for all responsive images
						alt=""
						hidden
						role="presentation"
						data-width-multiplier={backgroundWidthMultiplier}
						data-testid="background-image"
						// conditional loading props based on isPriorityContent
						{...getImgLoadingProps({
							/**
							 * NOTE ::
							 * Once the `flagRenderATF` is removed,
							 * you can just use `isPriorityContent`
							 * instead of `isPriorityContent: isPriorityContent`
							 */
							isPriorityContent:
								isPriorityContent && flagRenderATF,
							resizeImageUrl,
							classList: defaultImgClassList
						})}
					/>
				)}
			</div>
		);
	}
	return null;
};

Background.defaultProps = {
	imageLocation: '',
	imageUrl: '',
	imagePosition: '',
	imageSize: '',
	backgroundColor: '',
	backgroundGradient: '',
	backgroundRepeat: '',
	backgroundTransitionProperty: '',
	backgroundTransitionTimingFunction: '',
	backgroundTransitionDuration: '',
	backgroundWidthMultiplier: '1',
	foregroundImageCircle: false,
	foregroundImageHeight: 0,
	foregroundImageWidth: 0,
	foregroundImageMargin: '',
	foregroundImagePadding: '',
	foregroundImageAlignment: '',
	isPriorityContent: false,
	deviceType: ''
};

Background.propTypes = {
	showImage: PropTypes.bool.isRequired,
	imageLocation: PropTypes.string,
	imageUrl: PropTypes.string,
	imagePosition: PropTypes.string,
	imageSize: PropTypes.string,
	backgroundColor: PropTypes.string,
	backgroundGradient: PropTypes.string,
	backgroundRepeat: PropTypes.string,
	backgroundTransitionProperty: PropTypes.string,
	backgroundTransitionTimingFunction: PropTypes.string,
	backgroundTransitionDuration: PropTypes.string,
	backgroundWidthMultiplier: PropTypes.string,
	foregroundImageCircle: PropTypes.bool,
	foregroundImageHeight: PropTypes.number,
	foregroundImageWidth: PropTypes.number,
	foregroundImageMargin: PropTypes.string,
	foregroundImagePadding: PropTypes.string,
	foregroundImageAlignment: PropTypes.string,
	isPriorityContent: PropTypes.bool,
	flagRenderATF: PropTypes.bool,
	deviceType: PropTypes.string
};

export default Background;
