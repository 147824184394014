import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { setClassNames } from '../utilities/classes';

const SpotIcon = ({
	showIcon,
	spotIconName,
	spotIconSize,
	spotIconMargin,
	spotIconPadding,
	spotIconAlignment
}) => {
	if (showIcon && spotIconName && spotIconSize) {
		return (
			<SVG
				src={`/static/assets/icons/spot/${spotIconName}.svg`}
				aria-hidden="true"
				height={spotIconSize}
				width={spotIconSize}
				loader={
					<div
						style={{
							width: `${spotIconSize}px`,
							height: `${spotIconSize}px`
						}}
					/>
				}
				className={setClassNames(
					spotIconMargin,
					spotIconPadding,
					spotIconAlignment
				)}
			/>
		);
	}
	return null;
};

SpotIcon.defaultProps = {
	spotIconMargin: '',
	spotIconPadding: '',
	spotIconAlignment: ''
};

SpotIcon.propTypes = {
	showIcon: PropTypes.bool.isRequired,
	spotIconName: PropTypes.string.isRequired,
	spotIconSize: PropTypes.string.isRequired,
	spotIconMargin: PropTypes.string,
	spotIconPadding: PropTypes.string,
	spotIconAlignment: PropTypes.string
};

export default SpotIcon;
