import { SET_HOVER } from '../actions/types';

export default function hover(state = {}, action) {
	if (!action.error && action.payload) {
		switch (action.type) {
			case SET_HOVER:
				return action.payload;
			// no default
		}
	}

	return state;
}
