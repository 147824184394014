import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from '../utilities/classes';

const Content = ({
	showContent,
	contentText,
	contentSize,
	contentColor,
	contentMargin,
	contentPadding,
	contentAlignment,
	contentTransitionProperty,
	contentTransitionTimingFunction,
	contentTransitionDuration
}) => {
	if (showContent && contentText !== '') {
		return (
			<p
				className={setClassNames(
					contentSize,
					contentColor,
					contentMargin,
					contentPadding,
					contentAlignment,
					contentTransitionProperty,
					contentTransitionTimingFunction,
					contentTransitionDuration
				)}
				data-testid="content-test"
			>
				{contentText}
			</p>
		);
	}
	return null;
};

Content.defaultProps = {
	contentSize: '',
	contentColor: '',
	contentMargin: '',
	contentPadding: '',
	contentAlignment: '',
	contentTransitionProperty: '',
	contentTransitionTimingFunction: '',
	contentTransitionDuration: ''
};

Content.propTypes = {
	showContent: PropTypes.bool.isRequired,
	contentText: PropTypes.string.isRequired,
	contentSize: PropTypes.string,
	contentColor: PropTypes.string,
	contentMargin: PropTypes.string,
	contentPadding: PropTypes.string,
	contentAlignment: PropTypes.string,
	contentTransitionProperty: PropTypes.string,
	contentTransitionTimingFunction: PropTypes.string,
	contentTransitionDuration: PropTypes.string
};

export default Content;
