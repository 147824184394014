import React from 'react';
import PropTypes from 'prop-types';

import Icon from './Icon';
import Button from './Button';
import Content from './Content';
import Heading from './Heading';
import Background from './Background';
import SpotIcon from './SpotIcon';

import { setClassNames } from '../utilities/classes';
import { SPOT } from '../global-constants';

const ContentWrapper = ({
	widgetConfiguration,
	windowId,
	deviceType,
	isClickableDivElement,
	isHovered,
	headingText,
	contentText,
	buttonText,
	dialogWindowTitleText,
	target
}) => {
	const {
		widgetAlignment,
		widgetJustify,
		widgetMargin,
		widgetPadding,
		textAlignment,
		contentWrapperWidth,
		contentWrapperTransitionProperty,
		contentWrapperTransitionTimingFunction,
		contentWrapperTransitionDuration,
		buttonTransitionProperty,
		buttonTransitionTimingFunction,
		buttonTransitionDuration,
		textColor,
		imageLocation,
		enableContentOverflowHidden,
		contentWrapperFlexDisplay,
		contentWrapperClasses,
		iconType
	} = widgetConfiguration;

	const IconElement = iconType === SPOT ? SpotIcon : Icon;

	return (
		<div
			style={enableContentOverflowHidden ? { overflow: 'hidden' } : null}
			className={setClassNames(
				'cta-content',
				'd-flex',
				contentWrapperFlexDisplay,
				contentWrapperClasses,
				'position-relative',
				widgetAlignment,
				widgetJustify,
				widgetMargin,
				widgetPadding,
				textAlignment,
				textColor,
				contentWrapperWidth,
				contentWrapperTransitionProperty,
				contentWrapperTransitionTimingFunction,
				contentWrapperTransitionDuration
			)}
			data-testid="content-wrapper"
		>
			<IconElement {...widgetConfiguration} />
			{imageLocation === 'foreground' && (
				<Background {...widgetConfiguration}/>
			)}
			<Heading {...{ ...widgetConfiguration, windowId, headingText }} />
			<Content {...{ ...widgetConfiguration, contentText }} />
			<Button
				{...{
					...widgetConfiguration,
					windowId,
					deviceType,
					isClickableDivElement,
					isHovered,
					target,
					dialogWindowTitleText,
					buttonText,
					buttonTransitionProperty,
					buttonTransitionTimingFunction,
					buttonTransitionDuration
				}}
			/>
		</div>
	);
};

ContentWrapper.defaultProps = {
	headingText: '',
	contentText: '',
	buttonText: '',
	dialogWindowTitleText: ''
};

ContentWrapper.propTypes = {
	widgetConfiguration: PropTypes.shape({
		widgetAlignment: PropTypes.string,
		widgetJustify: PropTypes.string,
		widgetMargin: PropTypes.string,
		widgetPadding: PropTypes.string,
		textAlignment: PropTypes.string,
		contentWrapperWidth: PropTypes.string.isRequired,
		contentWrapperTransitionProperty: PropTypes.string.isRequired,
		contentWrapperTransitionTimingFunction: PropTypes.string.isRequired,
		contentWrapperTransitionDuration: PropTypes.string.isRequired,
		buttonTransitionProperty: PropTypes.string.isRequired,
		buttonTransitionTimingFunction: PropTypes.string.isRequired,
		buttonTransitionDuration: PropTypes.string.isRequired,
		textColor: PropTypes.string.isRequired,
		imageLocation: PropTypes.string,
		enableContentOverflowHidden: PropTypes.bool.isRequired,
		contentWrapperFlexDisplay: PropTypes.string.isRequired,
		contentWrapperClasses: PropTypes.string,
		iconType: PropTypes.string,
		isPriorityContent: PropTypes.bool,
		flagRenderATF: PropTypes.bool
	}).isRequired,
	windowId: PropTypes.string.isRequired,
	deviceType: PropTypes.string.isRequired,
	isClickableDivElement: PropTypes.bool.isRequired,
	isHovered: PropTypes.bool,
	target: PropTypes.string.isRequired,
	headingText: PropTypes.string,
	contentText: PropTypes.string,
	buttonText: PropTypes.string,
	dialogWindowTitleText: PropTypes.string
};

export default ContentWrapper;
