import { SERVICE_SCHEDULE_BUTTON } from '../../global-constants';

/**
 * This helper function will deduce the data location for a given link
 * @param {string} href - An url value
 */
export const getCtaType = (href = '', alias = '') => {
	if (
		alias.includes('SERVICE_LEAD') ||
		alias.includes('SCHEDULE_SERVICE') ||
		(href.includes('service') &&
			(href.includes('schedule') || href.includes('appointment')))
	) {
		return SERVICE_SCHEDULE_BUTTON;
	}

	return 'unknown';
};

const getWiapiAttributeNameList = (wiapiOverrideAttrs) =>
	typeof wiapiOverrideAttrs === 'object'
		? Object.keys(wiapiOverrideAttrs)
		: [];

/**
 * This method would change the default configuration of the widget
 * based on the wiapiOverrides
 *
 * @param {object} defaultConfiguration - The default widget config set based on the widget's pref
 * @param {object} wiapiOverrides - The override object that is obtained from DDC.PrivateAPI.getPageLevelModifications()
 * @param {string} deviceType - The request data device ('MOBILE', 'DESKTOP')
 * @param {string} windowId - The window id of the widget
 *
 * A sample wiapi override would look like this
 *
 *	{
 *		"integration": "some-third-party",
 *		"schema": {
 *			"href": "www.foo.bar",
 *			"target": "_blank",
 *			"attributes": {
 *				"data-width": "400px",
 *				"data-title": "My custom service"
 *			}
 *		}
 *	}
 */
export const modifyWidgetConfiguration = (
	defaultConfiguration,
	wiapiOverrides,
	deviceType,
	windowId
) => {
	const modifiedConfiguration = { ...defaultConfiguration };
	const { schema: overrideSchema, integration, location } = wiapiOverrides;
	const { attributes: wiapiOverrideAttrs } = overrideSchema;

	const wiapiDataAttrNameList = getWiapiAttributeNameList(wiapiOverrideAttrs);

	// Markers to identify that the WIAPI has changed this cta
	// This will be useful as debug info
	modifiedConfiguration.wiapiDataAttr = {
		'data-modified-by': integration,
		'data-content-source': 'Web Integration API',
		'data-page-location': location
	};

	Object.keys(overrideSchema).forEach((attr) => {
		switch (attr) {
			case 'href':
				modifiedConfiguration.url = overrideSchema[attr];
				break;

			case 'target':
				modifiedConfiguration.enableOpenNewWindow =
					overrideSchema[attr] === '_blank';
				break;

			case 'onclick':
				modifiedConfiguration.wiapiOnClick = overrideSchema[attr];
				break;

			case 'attributes': {
				wiapiDataAttrNameList.forEach((attribute) => {
					/**
					 * Based on some of the wiapi data attr override we
					 * would have to update the corresponding setting inside 'modifiedConfiguration'
					 */
					switch (attribute) {
						case 'data-title':
							modifiedConfiguration.dialogWindowTitleLabel =
								wiapiOverrideAttrs[attribute];
							break;

						case 'data-width':
							modifiedConfiguration.dialogWindowWidth =
								wiapiOverrideAttrs[attribute];
							break;

						case 'data-href':
							modifiedConfiguration.url =
								wiapiOverrideAttrs[attribute];
							break;

						default:
							modifiedConfiguration.wiapiDataAttr = {
								...modifiedConfiguration.wiapiDataAttr,
								[attribute]: wiapiOverrideAttrs[attribute]
							};
							break;
					}
				});
				break;
			}

			default:
				break;
		}
	});

	// If, the wiapi attr has any one of the mentioned attrs
	// we consider that the web integration is trying to open a modal
	const wiapiHasModalAttr = wiapiDataAttrNameList.some((dataAttr) =>
		['data-title', 'data-width', 'data-href'].includes(dataAttr)
	);

	// Enable the modal window setting enableDialogWindow and any related settings
	// If, any modal related data-attr is present in the wiapi override
	if (wiapiHasModalAttr) {
		// We need to activate the slide-in module for mobile
		// if the cta is not a modal type cta initially
		if (
			deviceType === 'MOBILE' &&
			modifiedConfiguration.enableDialogWindow === false
		) {
			const targetWidgetAnchor = document.querySelector(
				`[data-widget-id='${windowId}'] a`
			);
			window.DDC.mobile.slidein.init(targetWidgetAnchor);
		}

		modifiedConfiguration.enableDialogWindow = true;

		// Any wiapi onclick override set needs to be removed
		// And, don't open a new window
		modifiedConfiguration.enableOpenNewWindow = false;
		delete modifiedConfiguration.wiapiOnClick;
	} else {
		modifiedConfiguration.enableDialogWindow = false;

		// When there is an onClick set.
		// Reset the CTA link and we don't open a new window
		if (overrideSchema.onclick) {
			modifiedConfiguration.url = '#';
			modifiedConfiguration.enableOpenNewWindow = false;
		}
	}

	return modifiedConfiguration;
};
