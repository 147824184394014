import React from 'react';
import PropTypes from 'prop-types';
import { handleButtonClick } from '../utilities/events';
import { setClassNames, setDialogClasses } from '../utilities/classes';
import { setHoverValue } from '../utilities/helpers/hover';

const Button = ({
	windowId,
	deviceType,
	target,
	url,
	wiapiOnClick,
	isClickableDivElement,
	isHovered,
	enableDialogWindow,
	dialogWindowWidth,
	dialogWindowTitleText,
	buttonTransitionProperty,
	buttonTransitionTimingFunction,
	buttonTransitionDuration,
	showButton,
	buttonText,
	buttonStyle,
	buttonSize,
	buttonMargin,
	buttonAlignment,
	wiapiDataAttr
}) => {
	if (showButton && url && buttonText !== '') {
		return (
			<a
				href={url}
				target={target || null}
				id={`${windowId}-button`}
				data-modal={enableDialogWindow ? 'true' : null}
				className={setClassNames(
					'btn',
					setDialogClasses(enableDialogWindow, deviceType),
					buttonStyle,
					buttonSize,
					buttonMargin,
					buttonAlignment,
					buttonTransitionProperty,
					buttonTransitionTimingFunction,
					buttonTransitionDuration,
					setHoverValue(isHovered, 'hover', '')
				)}
				data-title={
					enableDialogWindow && dialogWindowTitleText
						? dialogWindowTitleText
						: null
				}
				data-testid="button"
				data-width={
					enableDialogWindow && dialogWindowWidth
						? dialogWindowWidth
						: null
				}
				onClick={(event) => {
					handleButtonClick({
						event,
						isClickableDivElement,
						element: 'ws-content-cta button',
						elementCTA: buttonText,
						result: url,
						windowId,
						wiapiOnClick
					});
				}}
				{...wiapiDataAttr}
			>
				{buttonText}
			</a>
		);
	}
	return null;
};

Button.defaultProps = {
	target: '',
	enableDialogWindow: false,
	dialogWindowWidth: '',
	dialogWindowTitleText: '',
	buttonTransitionProperty: '',
	buttonTransitionTimingFunction: '',
	buttonTransitionDuration: '',
	buttonStyle: '',
	buttonSize: '',
	buttonMargin: '',
	buttonAlignment: '',
	wiapiOnClick: null,
	wiapiDataAttr: {}
};

Button.propTypes = {
	windowId: PropTypes.string.isRequired,
	deviceType: PropTypes.string.isRequired,
	target: PropTypes.string,
	url: PropTypes.string.isRequired,
	wiapiOnClick: PropTypes.func,
	isClickableDivElement: PropTypes.bool.isRequired,
	isHovered: PropTypes.bool,
	enableDialogWindow: PropTypes.bool,
	dialogWindowWidth: PropTypes.string,
	dialogWindowTitleText: PropTypes.string,
	buttonTransitionProperty: PropTypes.string,
	buttonTransitionTimingFunction: PropTypes.string,
	buttonTransitionDuration: PropTypes.string,
	showButton: PropTypes.bool.isRequired,
	buttonText: PropTypes.string.isRequired,
	buttonStyle: PropTypes.string,
	buttonSize: PropTypes.string,
	buttonMargin: PropTypes.string,
	buttonAlignment: PropTypes.string,
	wiapiDataAttr: PropTypes.shape({})
};

export default Button;
