import { BLANK_GIF } from '../global-constants';
import { setClassNames } from './classes';
import { setImageUrl } from './images';

/**
 * Sets the background classes based on configuration parameters
 * Returns string with joined classes or null if no classes are defined
 * @param {string} imageLocation - A string value for image location (i.e. 'foreground' or 'background')
 * @param {boolean} foregroundImageCircle - A boolean value for displaying foreground image as a circle
 * @param {string} foregroundImageMargin - A string value for image margin class
 * @param {string} foregroundImagePadding - A string value for image padding class
 * @param {string} foregroundImageAlignment - A string value for image alignment class
 * @param {string} backgroundColor - A string value for background color class
 * @param {boolean} isPriorityContent - A boolean value to pre render an image at the top of the page
 * @param {boolean} flagRenderATF - Flag for rendering `isPriorityContent` Above The Fold feature
 */
export const setBackgroundClassNames = (
	imageLocation,
	foregroundImageCircle,
	foregroundImageMargin,
	foregroundImagePadding,
	foregroundImageAlignment,
	backgroundColor,
	isPriorityContent,
	flagRenderATF,
	...classes
) => {
	const classNames = [...classes];

	if (imageLocation === 'foreground') {
		classNames.push('cta-foreground');
		classNames.push(foregroundImageMargin);
		classNames.push(foregroundImagePadding);
		classNames.push(foregroundImageAlignment);

		if (foregroundImageCircle) {
			classNames.push('img-circle');
		}
	} else {
		classNames.push(backgroundColor);
		classNames.push('cta-background', 'h-100', 'w-100');

		if (imageLocation === 'background') {
			classNames.push('position-absolute');
		}
	}

	if (isPriorityContent && flagRenderATF) {
		// remove lazy-image class if it's already been set
		const priroityClassNames = classNames.filter((className) => {
			return !className.includes('lazy');
		});
		return setClassNames.apply(this, priroityClassNames);
	}

	return setClassNames.apply(this, classNames);
};

/**
 * Sets the background styles based on configuration parameters
 * Returns an object with all styles defined
 * @param {boolean} showImage - A boolean value for displaying image
 * @param {string} imageLocation - A string value for image location (i.e. 'foreground' or 'background')
 * @param {string} imageUrl - A string value for image URL
 * @param {string} imagePosition - A string value for background position
 * @param {string} imageSize - A string value for background size
 * @param {string} backgroundGradient - A string value for background gradient
 * @param {string} backgroundRepeat - A string value for background repeat
 * @param {number} foregroundImageHeight - A number value for height
 * @param {number} foregroundImageWidth - A number value for width
 * @param {boolean} hasResponsiveImage - A boolean value for whether image is responsive
 * @param {string} backgroundImage - A string value for background image src
 */
export const setBackgroundStyles = (
	showImage,
	imageLocation,
	resizeImageUrl,
	imagePosition,
	imageSize,
	backgroundGradient,
	backgroundRepeat,
	foregroundImageHeight,
	foregroundImageWidth,
	hasResponsiveImage
) => {
	const styles = {};
	let backgroundImageUrl = resizeImageUrl || BLANK_GIF;

	if (showImage) {
		if (!hasResponsiveImage) {
			backgroundImageUrl = setImageUrl(
				resizeImageUrl,
				foregroundImageHeight,
				foregroundImageWidth,
				imageLocation
			);
		}

		if (imagePosition) {
			styles.backgroundPosition = imagePosition;
		}
		if (imageSize) {
			styles.backgroundSize = imageSize;
		}

		if (imageLocation === 'foreground') {
			styles.flex = 'none';

			if (foregroundImageHeight) {
				styles.height = `${foregroundImageHeight}px`;
			}
			if (foregroundImageWidth) {
				styles.width = `${foregroundImageWidth}px`;
			}
			if (resizeImageUrl) {
				styles.backgroundImage = `url('${backgroundImageUrl}')`;
				styles.backgroundRepeat = 'no-repeat';
			}
		} else {
			if (backgroundGradient && resizeImageUrl) {
				styles.backgroundImage = `${backgroundGradient}, url('${backgroundImageUrl}')`;
			} else if (backgroundGradient) {
				styles.backgroundImage = backgroundGradient;
			} else if (resizeImageUrl) {
				styles.backgroundImage = `url('${backgroundImageUrl}')`;
			}

			if (backgroundRepeat) {
				styles.backgroundRepeat = backgroundRepeat;
			}
		}
	} else if (backgroundGradient) {
		styles.backgroundImage = backgroundGradient;
	}

	return styles;
};
