/**
 * Determines if a specific URL matches conditional checks
 * Returns a boolean that checks for specific Dealer.com image URLs
 * @param {string} url - A URL string value
 */
export const isDealerImage = (url) => {
	const domainRegex =
		/(.*\/\/.*)?(pictures|images|static)(\.)([^.]+\.)?dealer\.com(.*)/;
	return domainRegex.test(url) || url.startsWith('/static/');
};

/**
 * Determines if a specific URL contains our `impolicy` URL parameter
 * Returns a boolean that checks for impolicy param
 * @param {string} imageUrl - A URL string value
 */
export const hasImpolicy = (imageUrl) =>
	imageUrl ? imageUrl.includes('impolicy') : false;

/**
 * Sets the proper image dimension based on if it should be equal height and width
 * Returns an image dimension integer value based on the `dimensionType`
 * @param {string} dimensionType - A string value of 'height' or 'width'
 * @param {string} deviceType - A string value of 'DESKTOP' or 'MOBILE' to set appropriate fallback values
 * @param {integer} height - A number value setting the height
 * @param {integer} width - A number value setting the width
 * @param {boolean} isEqualDimensions - A boolean determining if the dimensions should be equal
 */
export const setImageDimension = (
	dimensionType = 'width',
	deviceType = 'DESKTOP',
	height = 0,
	width = 0,
	isEqualDimensions = false
) => {
	let imageDimension =
		dimensionType.toLowerCase().trim() === 'width' ? width : height;

	if (isEqualDimensions) {
		if ((!height && width) || (width && height !== width)) {
			imageDimension = width;
		} else if (!width && height) {
			imageDimension = height;
		} else if (height !== width || (width === 0 && height === 0)) {
			imageDimension = deviceType === 'DESKTOP' ? 192 : 160;
		}
	}

	return imageDimension;
};

/**
 * Determines a imageUrl have the param
 * @param {string} url - A string value of image url
 * @param {string} param - A string value to be checked within the `url` param string
 */
export const hasUrlParam = (url = '', param = '') => {
	const paramName = param.split('=');
	const paramRegex = new RegExp(`.*[?&]${paramName[0]}=.*`);
	return paramRegex.test(url);
};

/**
 * Determines the imageUrl to be added as a URL parameter 
 * @param {string} url - A URL string value
 * @param {string} param  - A string value to be added as a URL parameter
 */
export const setUrlParam = (url = '', param = '') => {
	if (hasUrlParam(url, param) || !param) {
		return url;
	}
	const separator = url && url.includes('?') ? '&' : '?';
	return `${url}${separator}${param}`;
};

/**
 * Return an image URL with Akamai optimization query string parameters
 * @param {string} imageSrc - A URL string value
 * @param {number} parentWidth - A numeric width 
 */
export const optimizeImage = (imageSrc, parentWidth = 0) => {
	const optimized = {
		src: '',
		parentWidth: 0
	};

	if (parentWidth > 0) {
		if (isDealerImage(imageSrc)) {
			optimized.parentWidth = parentWidth;
			optimized.src = setUrlParam(
				imageSrc,
				`impolicy=downsize_bkpt&w=${parentWidth}`
			);
		} else {
			optimized.src = imageSrc;
		}
	}

	return optimized;
};
