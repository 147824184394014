import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from '../utilities/classes';

const Heading = ({
	showHeading,
	headingText,
	headingMargin,
	headingPadding,
	headingAlignment,
	headingElement,
	headingSize,
	headingColor,
	headingClasses,
	headingTransitionProperty,
	headingTransitionTimingFunction,
	headingTransitionDuration,
	windowId
}) => {
	if (showHeading && headingText !== '') {
		const HeadingElement = headingElement;

		return (
			<HeadingElement
				data-testid="heading"
				id={`${windowId}-heading`}
				className={setClassNames(
					headingClasses,
					headingSize,
					headingColor,
					headingMargin,
					headingPadding,
					headingAlignment,
					headingTransitionProperty,
					headingTransitionTimingFunction,
					headingTransitionDuration
				)}
			>
				{headingText}
			</HeadingElement>
		);
	}
	return null;
};

Heading.defaultProps = {
	headingMargin: '',
	headingPadding: '',
	headingAlignment: '',
	headingElement: '',
	headingSize: '',
	headingColor: '',
	headingClasses: '',
	headingTransitionProperty: '',
	headingTransitionTimingFunction: '',
	headingTransitionDuration: ''
};

Heading.propTypes = {
	showHeading: PropTypes.bool.isRequired,
	headingText: PropTypes.string.isRequired,
	headingMargin: PropTypes.string,
	headingPadding: PropTypes.string,
	headingAlignment: PropTypes.string,
	headingElement: PropTypes.string,
	headingSize: PropTypes.string,
	headingColor: PropTypes.string,
	headingClasses: PropTypes.string,
	headingTransitionProperty: PropTypes.string,
	headingTransitionTimingFunction: PropTypes.string,
	headingTransitionDuration: PropTypes.string,
	windowId: PropTypes.string.isRequired
};

export default Heading;
