import { SET_WIDGET_INFO } from '../actions/types';

export default function widgetInfo(state = {}, action) {
	if (!action.error && action.payload) {
		switch (action.type) {
			case SET_WIDGET_INFO:
				return action.payload;
			// no default
		}
	}

	return state;
}
