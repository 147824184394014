/**
 * Triggers a tracking event using data from widget
 * Returns a tracking object within a triggered `trackEvent` event
 * Refer to https://fuel.coxautoinc.com/docs/DOC-144882 for usage and defaults
 * @param {object} eventData - An object containing event data
 */
export const trackGoogleAnalyticsEvent = (eventData, window) => {
	const defaultClickEvent = {
		event: 'widgetUserEvent',
		action: 'clicked',
		widgetState: 'default',
		fieldValue: undefined,
		formId: undefined,
		formEventId: undefined,
		value: undefined,
		widgetName: 'ws-content-cta'
	};

	if (window && window.DDC)
		return window.DDC.fireTrackingEvent({
			...defaultClickEvent,
			...eventData
		});

	return null;
};
