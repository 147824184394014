import React from 'react';
import PropTypes from 'prop-types';
import { setClassNames } from '../utilities/classes';

const Icon = ({
	showIcon,
	iconName,
	iconSize,
	iconColor,
	iconMargin,
	iconPadding,
	iconAlignment,
	iconTransitionProperty,
	iconTransitionDuration
}) => {
	if (showIcon && iconName !== '') {
		return (
			<i
				className={setClassNames(
					'ddc-icon',
					'line-height-reset',
					iconName,
					iconSize,
					iconColor,
					iconMargin,
					iconPadding,
					iconAlignment,
					iconTransitionProperty,
					iconTransitionDuration
				)}
				aria-hidden="true"
				data-testid="icon"
			/>
		);
	}
	return null;
};

Icon.defaultProps = {
	iconSize: '',
	iconColor: '',
	iconMargin: '',
	iconPadding: '',
	iconAlignment: '',
	iconTransitionProperty: '',
	iconTransitionDuration: ''
};

Icon.propTypes = {
	showIcon: PropTypes.bool.isRequired,
	iconName: PropTypes.string.isRequired,
	iconSize: PropTypes.string,
	iconColor: PropTypes.string,
	iconMargin: PropTypes.string,
	iconPadding: PropTypes.string,
	iconAlignment: PropTypes.string,
	iconTransitionProperty: PropTypes.string,
	iconTransitionDuration: PropTypes.string
};

export default Icon;
