import { setHoverValue } from './helpers/hover';
import { setImageDimension } from './helpers/images';
import { setUrl, isStringAlias } from './helpers/links';
import { modifyWidgetConfiguration } from './helpers/wiapi';
import { trimObj, toBoolean, toInt, toIcon } from './helpers/format';
import { SYSTEM, SPOT } from '../global-constants';

/**
 * Sets the configuration object for the widget
 * Returns a new object based on widget display logic conditions
 * @param {string} deviceType - The request data device ('MOBILE', 'DESKTOP')
 * @param {boolean} isHovered - The hover event of the entire app
 * @param {string} displayStyle - The display style of the widget
 * @param {object} prefs - The preferences object
 * @param {string} windowId - The window id of the widget
 * @param {object} wiapiOverrides - The web integration overrides for the widget
 * @param {boolean} isPriorityContent - A boolean value to pre render an image at the top of the page
 * @param {boolean} flagRenderATF - Flag for rendering `isPriorityContent` Above The Fold feature
 */
export const setWidgetConfiguration = (
	deviceType,
	isHovered,
	displayStyle,
	prefs = {},
	sitemap,
	windowId,
	wiapiOverrides = {},
	isPriorityContent = false,
	flagRenderATF = false
) => {
	const trimmedPrefs = trimObj(prefs);

	const formattedConfiguration = {
		...trimmedPrefs,
		enableClickable: toBoolean(trimmedPrefs.enableClickable),
		enableOpenNewWindow: toBoolean(trimmedPrefs.enableOpenNewWindow),
		enableDialogWindow: toBoolean(trimmedPrefs.enableDialogWindow),
		showIcon: toBoolean(trimmedPrefs.showIcon),
		showImage: toBoolean(trimmedPrefs.showImage),
		showHeading: toBoolean(trimmedPrefs.showHeading),
		showContent: toBoolean(trimmedPrefs.showContent),
		showButton: toBoolean(trimmedPrefs.showButton),
		foregroundImageCircle: toBoolean(trimmedPrefs.foregroundImageCircle),
		foregroundImageHeight: toInt(trimmedPrefs.foregroundImageHeight),
		foregroundImageWidth: toInt(trimmedPrefs.foregroundImageWidth),
		iconName: toIcon(trimmedPrefs.iconName)
	};

	let defaultConfiguration = {
		...formattedConfiguration,
		enableContentOverflowHidden: true,
		url: setUrl(sitemap, formattedConfiguration.url),
		alias: isStringAlias(formattedConfiguration.url)
			? formattedConfiguration.url
			: '',
		backgroundWrapperWidth: 'w-100',
		backgroundWrapperClasses: '',
		contentWrapperWidth: 'w-100',
		headingElement: formattedConfiguration.headingElement || 'h2',
		headingClasses: '',
		foregroundImageHeight: setImageDimension(
			'height',
			deviceType,
			formattedConfiguration.foregroundImageHeight,
			formattedConfiguration.foregroundImageWidth,
			formattedConfiguration.foregroundImageCircle
		),
		foregroundImageWidth: setImageDimension(
			'width',
			deviceType,
			formattedConfiguration.foregroundImageHeight,
			formattedConfiguration.foregroundImageWidth,
			formattedConfiguration.foregroundImageCircle
		),
		widgetTransitionProperty:
			'transition-property-background-color transition-property-text-color',
		widgetTransitionTimingFunction: 'transition-timing-function-standard',
		widgetTransitionDuration: 'transition-duration-lg',
		backgroundTransitionProperty: 'transition-property-background-color',
		backgroundTransitionTimingFunction:
			'transition-timing-function-standard',
		backgroundTransitionDuration: 'transition-duration-lg',
		headingTransitionProperty: '',
		headingTransitionTimingFunction: '',
		headingTransitionDuration: '',
		contentWrapperTransitionProperty: '',
		contentWrapperTransitionTimingFunction: '',
		contentWrapperTransitionDuration: '',
		buttonTransitionProperty: '',
		buttonTransitionTimingFunction: '',
		buttonTransitionDuration: '',
		contentTransitionProperty: '',
		contentTransitionTimingFunction: '',
		contentTransitionDuration: '',
		contentWrapperFlexDisplay: 'flex-column',
		contentWrapperClasses: 'h-100',
		isPriorityContent,
		flagRenderATF,
		deviceType
	};

	const hoverConfiguration = {
		widgetBackgroundColor: setHoverValue(
			isHovered,
			defaultConfiguration.widgetBackgroundColorHover,
			defaultConfiguration.widgetBackgroundColor
		),
		textColor: setHoverValue(
			isHovered,
			defaultConfiguration.textColorHover,
			defaultConfiguration.textColor
		),
		backgroundColor: setHoverValue(
			isHovered,
			defaultConfiguration.backgroundColorHover,
			defaultConfiguration.backgroundColor
		),
		backgroundGradient: setHoverValue(
			isHovered,
			defaultConfiguration.backgroundGradientHover,
			defaultConfiguration.backgroundGradient
		)
	};

	const displayStyleConfiguration = {
		widgetAlignment: 'align-items-center',
		widgetJustify: '',
		widgetMargin: '',
		enableClickable: false,
		showIcon: false,
		showImage: true,
		showHeading: true,
		showContent: true,
		showButton: true,
		textAlignment: 'text-center',
		imageLocation: 'background',
		backgroundColor: '',
		backgroundColorHover: '',
		backgroundGradient: '',
		backgroundGradientHover: '',
		iconType: SYSTEM,
		iconMargin: '',
		iconPadding: '',
		iconAlignment: '',
		spotIconMargin: '',
		spotIconPadding: '',
		spotIconAlignment: '',
		foregroundImageMargin: '',
		foregroundImagePadding: '',
		foregroundImageAlignment: '',
		foregroundImageCircle: false,
		headingPadding: '',
		headingAlignment: '',
		headingMargin: 'mt-0',
		contentMargin: '',
		contentPadding: '',
		contentAlignment: '',
		buttonAlignment: 'align-self-center',
		buttonMargin: ''
	};

	// Override the computed default config
	// with wiapi overrides. If, one exists.
	if (Object.keys(wiapiOverrides).length > 0) {
		defaultConfiguration = modifyWidgetConfiguration(
			defaultConfiguration,
			wiapiOverrides,
			deviceType,
			windowId
		);
	}

	switch (displayStyle) {
		case 'icon-heading-content-cta-centered':
			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				enableClickable: trimmedPrefs.url !== '',
				widgetPadding: defaultConfiguration.widgetPadding || 'p-5',
				widgetJustify: 'justify-content-center',
				imageUrl: '',
				showIcon: true,
				iconMargin: 'm-0',
				headingMargin:
					defaultConfiguration.headingMargin || 'mt-6 mx-0 mb-4',
				contentMargin:
					defaultConfiguration.contentMargin || 'mt-0 mx-0 mb-4',
				buttonMargin: 'm-0'
			};
		case 'spot-icon-heading-content-cta-centered':
			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				enableClickable: trimmedPrefs.url !== '',
				widgetPadding: defaultConfiguration.widgetPadding || 'p-5',
				widgetJustify: 'justify-content-center',
				imageUrl: '',
				showIcon: true,
				iconType: SPOT,
				headingMargin:
					defaultConfiguration.headingMargin || 'mt-6 mx-0 mb-4',
				contentMargin:
					defaultConfiguration.contentMargin || 'mt-0 mx-0 mb-4',
				buttonMargin: 'm-0'
			};
		case 'image-heading-content-cta-centered':
			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				widgetPadding: 'p-5',
				widgetJustify: 'justify-content-center',
				imageLocation: 'foreground',
				foregroundImageCircle: true,
				foregroundImageHeight: setImageDimension(
					'height',
					deviceType,
					formattedConfiguration.foregroundImageHeight,
					formattedConfiguration.foregroundImageWidth,
					true
				),
				foregroundImageWidth: setImageDimension(
					'width',
					deviceType,
					formattedConfiguration.foregroundImageHeight,
					formattedConfiguration.foregroundImageWidth,
					true
				),
				foregroundImageMargin: 'mt-0 mx-0 mb-5',
				headingMargin: 'mt-0 mx-0 mb-4',
				contentMargin: 'mt-0 mx-0 mb-4',
				buttonMargin: 'm-0'
			};
		case 'background-image-zoom-heading': {
			const backgroundTransitionConfiguration =
				deviceType === 'DESKTOP'
					? {
							backgroundTransitionProperty: `transition-property-transform scale-${
								isHovered ? '110' : '100'
							}`,
							backgroundTransitionTimingFunction:
								'transition-timing-function-standard',
							backgroundTransitionDuration:
								'transition-duration-xl'
					  }
					: null;

			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...backgroundTransitionConfiguration,
				widgetPadding: 'p-5',
				enableClickable: true,
				showContent: false,
				showButton: false,
				headingMargin: 'mt-auto mb-0',
				headingColor: 'text-white',
				backgroundColor: 'bg-black',
				backgroundGradient:
					'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,.6) 100%)'
			};
		}
		case 'background-image-zoom-icon-heading': {
			const backgroundTransitionConfiguration =
				deviceType === 'DESKTOP'
					? {
							backgroundTransitionProperty: `transition-property-transform scale-${
								isHovered ? '110' : '100'
							}`,
							backgroundTransitionTimingFunction:
								'transition-timing-function-standard',
							backgroundTransitionDuration:
								'transition-duration-xl'
					  }
					: null;

			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...backgroundTransitionConfiguration,
				widgetJustify: 'justify-content-center',
				widgetPadding: 'p-5',
				enableClickable: true,
				showContent: false,
				showIcon: true,
				showButton: false,
				iconSize: defaultConfiguration.iconSize || 'icon-size-7',
				iconColor: defaultConfiguration.iconColor || 'text-primary-300',
				headingMargin: 'mt-3 mx-0 mb-0',
				headingColor: defaultConfiguration.headingColor || 'text-white',
				backgroundColor:
					defaultConfiguration.backgroundColor || 'bg-black',
				backgroundGradient:
					defaultConfiguration.backgroundGradient ||
					'linear-gradient(rgba(0,0,0,.6) 0%, rgba(0,0,0,.6) 100%)'
			};
		}
		case 'heading-left-image-right-zoom': {
			const backgroundTransitionConfiguration =
				deviceType === 'DESKTOP'
					? {
							backgroundTransitionProperty: `transition-property-transform scale-${
								isHovered ? '105' : '100'
							}`,
							backgroundTransitionTimingFunction:
								'transition-timing-function-standard',
							backgroundTransitionDuration:
								'transition-duration-xl'
					  }
					: null;

			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...backgroundTransitionConfiguration,
				widgetJustify: 'justify-content-center',
				widgetPadding: 'p-5',
				widgetBackgroundColor: isHovered
					? defaultConfiguration.widgetBackgroundColorHover ||
					  'bg-light'
					: defaultConfiguration.widgetBackgroundColor || '',
				enableClickable: true,
				showContent: false,
				showButton: false,
				backgroundWrapperWidth: 'w-50',
				contentWrapperWidth: 'w-50',
				imageLocation: 'background-left',
				textColor: isHovered
					? defaultConfiguration.textColorHover || 'text-primary'
					: defaultConfiguration.textColor || 'text-body',
				headingClasses: 'centered-border-bottom-sm',
				headingMargin: 'm-0',
				headingTransitionProperty: `transition-property-after-transform scale-after-${
					isHovered ? '150-100' : '100'
				}`,
				headingTransitionTimingFunction:
					'transition-timing-function-after-standard',
				headingTransitionDuration: 'transition-duration-after-md'
			};
		}
		case 'image-left-zoom-heading-right': {
			const backgroundTransitionConfiguration =
				deviceType === 'DESKTOP'
					? {
							backgroundTransitionProperty: `transition-property-transform scale-${
								isHovered ? '105' : '100'
							}`,
							backgroundTransitionTimingFunction:
								'transition-timing-function-standard',
							backgroundTransitionDuration:
								'transition-duration-xl'
					  }
					: null;

			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...backgroundTransitionConfiguration,
				widgetPadding: 'p-5',
				widgetJustify: 'justify-content-center',
				widgetBackgroundColor: isHovered
					? defaultConfiguration.widgetBackgroundColorHover ||
					  'bg-light'
					: defaultConfiguration.widgetBackgroundColor || '',
				enableClickable: true,
				showContent: false,
				showButton: false,
				backgroundWrapperWidth: 'w-50',
				contentWrapperWidth: 'w-50',
				imageLocation: 'background-right',
				textColor: isHovered
					? defaultConfiguration.textColorHover || 'text-primary'
					: defaultConfiguration.textColor || 'text-body',
				headingClasses: 'centered-border-bottom-sm',
				headingMargin: 'm-0',
				headingTransitionProperty: `transition-property-after-transform scale-after-${
					isHovered ? '150-100' : '100'
				}`,
				headingTransitionTimingFunction:
					'transition-timing-function-after-standard',
				headingTransitionDuration: 'transition-duration-after-md'
			};
		}
		case 'background-zoom-hover-rollup': {
			const backgroundTransitionConfiguration =
				deviceType === 'DESKTOP'
					? {
							backgroundTransitionProperty: `transition-property-transform scale-${
								isHovered ? '110' : '100'
							}`,
							backgroundTransitionTimingFunction:
								'transition-timing-function-standard',
							backgroundTransitionDuration:
								'transition-duration-xl'
					  }
					: null;
			let rollupConfiguration;

			if (deviceType === 'DESKTOP') {
				rollupConfiguration = {
					contentWrapperTransitionProperty: isHovered
						? 'translate-up-10'
						: 'translate-down-0',
					buttonTransitionProperty: isHovered
						? 'opacity-100'
						: 'opacity-0',
					widgetPadding: 'p-0',
					headingColor: 'text-white',
					headingMargin: 'm-2',
					buttonSize: defaultConfiguration.buttonSize || 'btn-xsmall',
					buttonMargin: 'mt-3'
				};
			} else {
				rollupConfiguration = {
					contentTransitionProperty: 'opacity-0',
					buttonTransitionProperty: 'opacity-100',
					showContent: false,
					widgetPadding: 'p-6',
					headingColor: 'text-white',
					headingMargin: 'm-4',
					buttonSize: defaultConfiguration.buttonSize || 'btn-small'
				};
			}

			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...backgroundTransitionConfiguration,
				...rollupConfiguration,
				contentWrapperTransitionTimingFunction:
					'transition-timing-function-standard',
				contentWrapperTransitionDuration: 'transition-duration-xl',
				buttonTransitionTimingFunction:
					'transition-timing-function-standard',
				buttonTransitionDuration: 'transition-duration-xl',
				enableClickable: true,
				showButton: true,
				buttonStyle: 'btn-default btn-inverse',
				contentColor: 'text-white',
				contentMargin: 'm-0',
				widgetJustify: 'justify-content-end',
				contentSize: 'h4',
				backgroundColor: 'bg-black',
				backgroundGradient:
					'linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,.6) 100%)'
			};
		}
		case 'light-icon-link-cta': {
			const deviceTypeConfiguration =
				deviceType === 'MOBILE'
					? {
							contentWrapperFlexDisplay: 'flex-column',
							iconMargin:
								defaultConfiguration.iconMargin || 'm-0',
							headingElement: 'h5'
					  }
					: {
							contentWrapperFlexDisplay: 'flex-row',
							iconMargin:
								defaultConfiguration.iconMargin || 'mr-4',
							headingElement: 'h4'
					  };
			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...deviceTypeConfiguration,
				imageUrl: '',
				showContent: false,
				showButton: false,
				showIcon: true,
				enableClickable: true,
				widgetPadding: defaultConfiguration.widgetPadding,
				widgetJustify: 'justify-content-center',
				widgetTransitionTimingFunction:
					'transition-timing-function-standard',
				iconColor: setHoverValue(
					isHovered,
					'text-primary-900',
					'text-primary-700'
				),
				iconPadding: 'p-0',
				iconSize: defaultConfiguration.iconSize || 'icon-size-6',
				iconTransitionProperty: 'transition-property-text-color',
				iconTransitionDuration: 'transition-duration-lg',
				textColor: setHoverValue(
					isHovered,
					'text-primary-700',
					'text-primary-500'
				),
				headingMargin: 'm-0',
				headingTransitionProperty: 'transition-property-text-color',
				headingTransitionDuration: 'transition-duration-lg'
			};
		}
		case 'dark-icon-link-cta-float': {
			const deviceTypeConfiguration =
				deviceType === 'MOBILE'
					? {
							contentWrapperFlexDisplay: 'flex-column',
							iconMargin:
								defaultConfiguration.iconMargin || 'm-0',
							headingElement: 'h5'
					  }
					: {
							contentWrapperFlexDisplay: 'flex-row',
							iconMargin:
								defaultConfiguration.iconMargin || 'mr-4',
							headingElement: 'h4'
					  };
			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				...deviceTypeConfiguration,
				imageUrl: '',
				showContent: false,
				showButton: false,
				showIcon: true,
				enableClickable: true,
				widgetJustify: 'justify-content-center',
				widgetTransitionProperty: setHoverValue(
					isHovered,
					'translate-up-spacers-2',
					'transition-property-transform translate-0'
				),
				widgetTransitionDuration: 'transition-duration-lg',
				widgetTransitionTimingFunction:
					'transition-timing-function-standard',
				iconColor: setHoverValue(
					isHovered,
					'text-neutral-0',
					defaultConfiguration.iconColor || 'text-primary-200'
				),
				iconPadding: 'p-0',
				iconSize: defaultConfiguration.iconSize || 'icon-size-6',
				iconTransitionProperty: 'transition-property-text-color',
				iconTransitionDuration: 'transition-duration-lg',
				textColor: setHoverValue(
					isHovered,
					'text-neutral-0',
					'text-primary-50'
				),
				headingMargin: 'm-0',
				headingTransitionProperty: 'transition-property-text-color',
				headingTransitionDuration: 'transition-duration-lg'
			};
		}
		case 'top-image-heading-content-cta':
			return {
				...defaultConfiguration,
				...displayStyleConfiguration,
				imageLocation: 'top',
				contentWrapperClasses: '',
				backgroundWrapperWidth: 'w-100',
				backgroundWrapperClasses: 'flex-grow-1'
			};
		default:
			return {
				...defaultConfiguration,
				...hoverConfiguration
			};
	}
};
