/**
 * Sets a value based on boolean param value
 * Returns a string value if hover state and hover value is present or
 * else it will return an empty string
 * @param {boolean} isHovered - A boolean value dictating returned value
 * @param {string} hoverValue - A string value for hover display state
 * @param {string} defaultValue - A string value for default display state
 */
export const setHoverValue = (
	isHovered = false,
	hoverValue = '',
	defaultValue = ''
) => {
	return isHovered && hoverValue ? hoverValue : defaultValue;
};
