import { trackGoogleAnalyticsEvent } from './helpers/track-event';

/**
 * Sets the event object for the Google anlytics event
 * Passes the object to the `trackGoogleAnalyticsEvent` helper function
 * @param {string} element - A string value for element
 * @param {string} elementCTA - A string value for elementCTA
 * @param {string} result - A string value for result
 * @param {string} windowId - A string value for windowId
 */
export const triggerTrackingEvent = ({
	element,
	elementCTA,
	url = '',
	elementType,
	result,
	windowId,
	ariaLabel = '',
	reactElementText = ''
} = {}) => {
	const eventData = {
		element,
		elementCTA,
		result,
		windowId,
		dataToExcludeFromWidgetUserEvent: {
			url,
			ariaLabel,
			elementType,
			reactElementText
		}
	};
	const { window } = global;

	return trackGoogleAnalyticsEvent(eventData, window);
};
