import { SET_HOVER } from './types';

export function setHover(isHovered = false) {
	return {
		type: SET_HOVER,
		payload: {
			isHovered
		}
	};
}
