import { DDC_ICON_PREFIX } from '../../global-constants';

/**
 * Trims the values in a flat object property
 * Returns an empty object if the argument is an empty object
 * @param {object} obj - A flat 'key: value' object
 */
export const trimObj = (obj = {}) => {
	return Object.keys(obj).reduce((acc, key) => {
		if (obj[key] !== null && typeof obj[key] === 'object') {
			throw new Error(
				`The property '${key}' is of type 'Object' that cannot be trimmed`
			);
		}
		acc[key] = obj[key].trim();
		return acc;
	}, {});
};

/**
 * Converts a string to a boolean value
 * Returns a boolean value with a default value of false
 * @param {string} value - A string value of "true" or "false"
 */
export const toBoolean = (value = '') => value.toLowerCase().trim() === 'true';

/**
 * Converts a string to an integer value
 * Returns an integer
 * @param {string} value - A string value to be converted to an integer
 */
export const toInt = (value = 0) => parseInt(value, 10) || 0;

/**
 * Converts a string to a `ddc-icon-` prefaced string value
 * Returns a string
 * @param {string} iconName - A string value to be converted to a DDC icon class for system icons.
 */
export const toIcon = (iconName = '') =>
	iconName &&
	(iconName.indexOf(DDC_ICON_PREFIX) === 0
		? iconName
		: `${DDC_ICON_PREFIX}${iconName}`);
